import React, { useState } from "react"
import Image from "../components/image"
import ModalProjects from "../components/modalProjects"
import "./projectCard.css"

const ProjectCard = inputProps => {
  const [isModalOpen, toggleModal] = useState(false)
  const {
    title,
    intro,
    description,
    features,
    mainImage,
    backgroundImage,
  } = inputProps.data
  const closeModal = () => {
    toggleModal(false)
  }
  const openModal = () => {
    toggleModal(true)
  }
  return (
    <>
      <ModalProjects
        title={title}
        data={inputProps.data}
        open={isModalOpen}
        onCloseModal={closeModal}
      />
      <div className="project" id="project-card" onClick={openModal}>
        <div className="project__data" id="project-data">
          <div className="project__poster">
            <span className="project__poster--fill">
              <img src={`/${backgroundImage}`} alt="project background" />
            </span>
            <span className="project__poster--featured">
              {mainImage.indexOf("iphone") !== -1 ? (
                <img src={`/${mainImage}`} alt="project main" />
              ) : (
                <Image imgName={mainImage} />
              )}
            </span>
          </div>
          <div className="project__details">
            <h2 className="project__title">{title}</h2>
            <ul className="project__tags list--inline">{intro}</ul>
            <p className="project__plot">{description}</p>
            <div className="project__credits">
              <p>
                <strong>Features:</strong> {features}
              </p>
              <p id="learn">Learn More &#8680;</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ProjectCard
