import React from "react"
import Card from "../components/card"
import ExperienceCard from "../components/experienceCard"
import Layout from "../components/layout"
import ProfileImage from "../components/profileImage"
import ProjectCard from "../components/projectCard"
import TechStackCard from "../components/techstackcard"
import SEO from "../components/seo"
import { IndexLink } from "../utils/commonComponents"
import { about, experiences, projects, techStack } from "../data/portfolio"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <ProfileImage />
    <IndexLink section="About Me" linkTo="/about" />
    <div
      data-sal="fade"
      data-sal-delay="200"
      data-sal-duration="500"
      data-sal-easing="ease"
    >
      <Card data={about.short} />
    </div>

    <IndexLink section="Professional Experience" linkTo="/experience" />
    {experiences.slice(0, 2).map((experience, idx) => (
      <div
        data-sal={idx % 2 === 0 ? "slide-right" : "slide-left"}
        data-sal-delay="200"
        data-sal-duration="500"
        data-sal-easing="ease"
        key={experience.company}
      >
        <ExperienceCard data={experience} key={experience.company} />
      </div>
    ))}
    <IndexLink section="Projects + Applications" linkTo="/projects" />
    {projects.slice(0, 2).map((project, idx) => (
      <div
        data-sal={idx % 2 === 0 ? "slide-right" : "slide-left"}
        data-sal-delay="200"
        data-sal-duration="500"
        data-sal-easing="ease-in-out"
        key={project.title}
      >
        <ProjectCard data={project} key={project.title} />
      </div>
    ))}
    <IndexLink section="Technical Skills" linkTo="/skills" />
    {techStack.slice(0, 2).map(tech => (
      <div
        data-sal="zoom-in"
        data-sal-delay="200"
        data-sal-duration="500"
        data-sal-easing="ease-in-out"
        key={tech[0].name}
      >
        <TechStackCard data={tech} key={tech[0].name} />
      </div>
    ))}
  </Layout>
)

export default IndexPage
