import React from "react"
import Image from "./image"
import styled from "styled-components"
import "./profileImage.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFileAlt,
  faMapPin,
  faCodeBranch,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons"
import {
  faLinkedin,
  faFacebook,
  faGithub,
} from "@fortawesome/free-brands-svg-icons"
import resumePDF from "../data/devonyuresume.pdf"

const Name = styled.h1`
color: rgba(0, 0, 0, 0.8);
-webkit-animation: puff-in-center .5s cubic-bezier(0.470, 0.000, 0.745, 0.715);
animation: puff-in-center .5s cubic-bezier(0.470, 0.000, 0.745, 0.715);
@-webkit-keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
}
@keyframes puff-in-center {
  0% {
    -webkit-transform: scale(2);
            transform: scale(2);
    -webkit-filter: blur(4px);
            filter: blur(4px);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-filter: blur(0px);
            filter: blur(0px);
    opacity: 1;
  }
`

const IconList = styled.ul`
  color: rgba(255, 255, 255, 0.75);
  text-align: right;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  margin: 0 0 2em 0;
  cursor: default;
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: space-between;
  & a {
    color: black;
    }
  }
  `
const IconSingle = styled.li`
  :hover span {
    visibility: visible;
  }
`

const ToolTip = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  border-radius: 6px;
  padding: 5px 5px;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  position: relative;
  z-index: 1;
`

const ProfileImage = () => {
  return (
    <div className="content">
      <span className="image">
        <Image imgName={"pf2.png"} />
      </span>
      <header>
        <Name>Devon Yu</Name>
        <p>
          <FontAwesomeIcon icon={faCodeBranch} /> Full Stack Software Engineer
        </p>
        <p>
          <FontAwesomeIcon icon={faMapPin} /> San Francisco, California
        </p>
        <IconList>
          <IconSingle>
            <a href={resumePDF} download>
              <FontAwesomeIcon icon={faFileAlt} />
            </a>
            <ToolTip>Resume</ToolTip>
          </IconSingle>
          <IconSingle>
            <a
              href="https://www.linkedin.com/in/devonyu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
            <ToolTip>LinkedIn</ToolTip>
          </IconSingle>
          <IconSingle>
            <a
              href="https://www.github.com/devonyu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faGithub} />
            </a>
            <ToolTip>Github</ToolTip>
          </IconSingle>
          <IconSingle>
            <a
              href="https://www.facebook.com/devonyu"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <ToolTip>Facebook</ToolTip>
          </IconSingle>
          <IconSingle>
            <a
              href="mailto:devonyu415@gmail.com?subject=Hello"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faPaperPlane} />
            </a>
            <ToolTip>Email</ToolTip>
          </IconSingle>
        </IconList>
      </header>
    </div>
  )
}

export default ProfileImage
