import React from "react"
import ReactModal from "react-modal"
import styled from "styled-components"

const customStyles = {
  overlay: {
    backgroundColor: "grey",
  },
  content: {
    borderRadius: ".8em",
    bottom: "auto",
    color: "rgba(0, 0, 0, 0.8)",
    height: "fit-content",
    left: "50%",
    marginRight: "-50%",
    marginTop: "20px",
    padding: "1em",
    paddingTop: "2.5em",
    right: "auto",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
  },
}

const CloseButton = styled.button`
  position: absolute;
  right: 0;
  top: 0;
  background: transparent;
  color: black;
  border: none;
  :hover {
    cursor: pointer;
  }
`
const ModalProjects = props => {
  if (typeof document !== `undefined`) {
    ReactModal.setAppElement(document.getElementById("root"))
  }
  return (
    <ReactModal
      isOpen={props.open}
      onRequestClose={props.onCloseModal}
      // onAfterOpen={() => (document.body.style.overflow = "hidden")}
      onAfterClose={() => (document.body.style.overflow = "unset")}
      style={customStyles}
      contentLabel="Project Modal"
    >
      <CloseButton id="close" onClick={props.onCloseModal}>
        &times;
      </CloseButton>
      <h1>{props.data.title}</h1>

      {props.data.coverage ? (
        <img src={props.data.coverage} alt="Coverage Status" />
      ) : null}
      {props.data.build ? (
        <img src={props.data.build} alt="Build Status" />
      ) : null}
      <ul className="project__tags list--inline">{props.data.intro}</ul>
      <p>
        <strong>Description: </strong>
        {props.data.description}
      </p>
      <p>
        <strong>Features: </strong>
        {props.data.features}
      </p>
      <p>
        <strong>Tech Stack: </strong>
        {props.data.techStack}
      </p>
      {props.data.upcomingFeatures ? (
        <p>
          <strong>Upcoming/planned features: </strong>
          {props.data.upcomingFeatures}
        </p>
      ) : null}
      {props.data.active ? (
        <p>
          <strong>Deployed App: </strong>
          <a href={props.data.active}>Try out the App Here</a>
        </p>
      ) : null}

      <p>
        <strong>GitHub Link: </strong>
        <a href={props.data.github}>{props.data.github}</a>
      </p>
    </ReactModal>
  )
}

export default ModalProjects
